import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'hpm-multi-search-select',
  templateUrl: './multi-search-select.component.html',
  styleUrl: './multi-search-select.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSearchSelectComponent),
      multi: true,
    },
  ],
})
export class MultiSearchSelectComponent
  implements OnInit, ControlValueAccessor
{
  @Input() label!: string;
  @Input() itemList!: string[];
  @Input() selectedItems: string[] = [];
  @Input() divider?: string = ',';
  @Input() multiFieldRequired = false;
  @Input() isDisabled = false;
  initialSelection: string[] = [];
  opened = false;
  form!: FormGroup;

  @ViewChild('formField') inputElementRef: ElementRef | undefined;

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(): void {
    this.abortDialog();
  }

  constructor(
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
  ) {
    this.form = this.formBuilder.group({
      inputField: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.disableForm(this.isDisabled);
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */ // any type required for CVA interface
  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /* eslint-enable */

  writeValue(value: string[]): void {
    if (value) {
      this.selectedItems = value;
      this.initialSelection = [...this.selectedItems];
    } else {
      this.selectedItems = [];
    }
    // Set the value manually, otherwise the control will not have the current value!
    this.form.controls['inputField'].setValue(this.getDisplayValue());
    this.cdr.detectChanges();
  }

  selectionFinished(): void {
    this.initialSelection = [...this.selectedItems];
    this.onChange(this.selectedItems);
    this.closeDialog();
  }

  openDialog(): void {
    this.opened = true;
  }

  disableForm(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable({ emitEvent: false });
    }
  }

  abortDialog(): void {
    this.selectedItems = [...this.initialSelection];
    this.closeDialog();
  }

  closeDialog(): void {
    this.opened = false;
    if (this.inputElementRef) {
      this.inputElementRef.nativeElement.focus();
    }
    this.form.controls['inputField'].updateValueAndValidity();
  }

  getDisplayValue(): string {
    return [...this.selectedItems]
      .sort((a, b) => {
        return a.toLowerCase().localeCompare(b.toLowerCase());
      })
      .join(`${this.divider} `);
  }

  updateValueAndValidity(): void {
    this.form.controls['inputField'].updateValueAndValidity();
    this.form.markAllAsTouched();
  }
}
