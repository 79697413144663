import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthorizedUser, User } from '../model/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserHttpService {
  private readonly userBaseUrl = `${environment.baseUrl}/user`;
  private currentUser: BehaviorSubject<AuthorizedUser | null> =
    new BehaviorSubject<AuthorizedUser | null>(null);

  constructor(private httpClient: HttpClient) {}

  getCurrentUser(): Observable<AuthorizedUser | null> {
    if (!this.currentUser.getValue()) {
      this.loadCurrentUser();
    }
    return this.currentUser.asObservable();
  }

  loadCurrentUser(): void {
    this.httpClient
      .get<AuthorizedUser>(`${this.userBaseUrl}/currentUser`)
      .subscribe((currentUser) => {
        this.currentUser.next(currentUser);
      });
  }

  getUserList(): Observable<User[]> {
    return this.httpClient.get<User[]>(`${this.userBaseUrl}/userlist`);
  }
}
