export interface User {
  id: string;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
}

export interface AuthorizedUser {
  id: string;
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  roles: UserRole[];
}

export function getDefaultUser(): AuthorizedUser {
  return {
    id: '',
    username: '',
    firstname: '',
    lastname: '',
    email: '',
    roles: [UserRole.FIELD_SERVICE],
  };
}

export function hasAnyOfThoseRoles(
  user: AuthorizedUser | null,
  roles: UserRole[],
): boolean {
  if (user && roles) {
    return roles.filter((item) => user.roles.includes(item)).length > 0;
  }
  return false;
}

export enum UserRole {
  FIELD_SERVICE = 'FIELD_SERVICE',
  OFFICE_SERVICE = 'OFFICE_SERVICE',
  RETAIL_PARTNER = 'RETAIL_PARTNER',
  ADMIN = 'ADMIN',
  MARKETING = 'MARKETING',
  CUSTOMER = 'CUSTOMER',
}
