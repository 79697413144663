<form [formGroup]="form" [attr.disabled]="isDisabled ? true : null">
  <mat-form-field (click)="isDisabled ? null : openDialog()"
                  (keyup.space)="isDisabled ? null : openDialog()"
                  class="multi-search-form-field"
                  [ngClass]="{'disabled-form-field': isDisabled}">
    <mat-label [ngClass]="{'disabled-text': isDisabled}">{{ label | translate }}</mat-label>
    <input #trigger="cdkOverlayOrigin"
           #formField
           formControlName="inputField"
           [value]="getDisplayValue()"
           cdkOverlayOrigin
           matInput
           [required]="multiFieldRequired"
           [ngClass]="{'disabled-input': isDisabled, 'input': !isDisabled}"
           readonly>
    <mat-error>{{ 'ORDER_DETAIL.GENERAL.REQUIRED_FIELD_MISSING' | translate }}</mat-error>
    <svg aria-hidden="true" [ngClass]="{'customDropdown-disabled': isDisabled, 'customDropdown': !isDisabled}"
         focusable="false" height="24px" viewBox="0 0 24 24" width="24px">
      <path d="M7 10l5 5 5-5z"/>
    </svg>
  </mat-form-field>

  <ng-template
    (backdropClick)="abortDialog()"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayOpen]="opened"
    [cdkConnectedOverlayOrigin]="trigger"
    cdk-connected-overlay
    cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  >
    <hpm-multi-search-select-dialog
      (selectionFinished)="selectionFinished()"
      *ngIf="opened"
      [(selectedItems)]="selectedItems"
      [itemList]="itemList"
      [label]="label"
    ></hpm-multi-search-select-dialog>
  </ng-template>
</form>
